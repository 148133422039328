import request from '@/utils/request';

const PATH = '/notification';

export const getNotificationList = () => request({
  url: PATH,
  method: 'get',
});

export const getNotificationRead = (idx: number) => request({
  url: `${PATH}/${idx}`,
  method: 'put',
});

export const getNotificationReadAll = () => request({
  url: `${PATH}/all`,
  method: 'put',
});

export const getNotificationDeleteAll = () => request({
  url: `${PATH}/all`,
  method: 'delete',
});
