
import {
  getNotificationList,
  getNotificationRead,
  getNotificationReadAll,
  getNotificationDeleteAll,
} from '@/api/notification';
import { Component, Vue } from 'vue-property-decorator';
import { Allim } from './allim';

@Component({
  components: {},
})

export default class extends Vue {
  mounted() {
    this.handleNotificationList();
  }

  private notificationLoading = true;

  private notificationList = [];

  private goBack() {
    this.$router.go(-1);
  }

  private allimSplit = {
    readStatus: [] as Object[],
    minutesDiff: [] as Object[],
    timeUnit: [] as Object[],
    title: [] as Object[],
    content: [] as Object[],
    message: [] as Object[],
  }

  private handleNotificationList() {
    getNotificationList().then((res: any) => {
      this.notificationList = res.data;
      this.notificationLoading = false;
      // this.formattedAllimMessage(this.notificationList);
      console.log(this.notificationList);
    });
  }

  private checkRead = false;

  private handleNotificationRead(item: any) {
    getNotificationRead(item.idx);
  }

  private handleNotificationReadAll(item: any) {
    getNotificationReadAll();
    window.location.reload();
  }

  private handleNotificationDeleteAll() {
    this.$confirm('정말 삭제하시겠습니까?', '확인', {
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      type: 'warning',
    }).then(() => {
      getNotificationDeleteAll().then(() => {
        this.notificationList = [];
      });
    });
  }

  private msg = '';

  private formattedAllimMessage = (msg: string) => {
    // this.msg = msg.split('\\n');
    this.msg = msg.replace(/\\n/g, ' ');

    return this.msg;
  }
}
